import { gql } from "@apollo/client";

export const getPayrollEnabledOrg = gql`
  query getPayrollEnabledOrg(
    $limit: Int!
    $offset: Int!
    $filter: organisations_bool_exp!
    $orderBy: [organisations_order_by!]
  ) {
    organisations(
      order_by: $orderBy
      where: {
        _and: [
          { automated_payroll_generation: { _eq: true } }
          { enabled: { _eq: true } }
          $filter
        ]
      }
      limit: $limit
      offset: $offset
    ) {
      id
      name
    }
    organisations_aggregate(
      where: {
        _and: [
          { automated_payroll_generation: { _eq: true } }
          { enabled: { _eq: true } }
          $filter
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getOrgPayrollSetting = gql`
  query getOrgPayrollSetting($organisation_id: Int!) {
    organisation_payroll_settings(
      where: { organisation_id: { _eq: $organisation_id } }
    ) {
      id
      updated_at
      created_at
      organisation_id
      payroll_frequency
      payroll_identifier
      purchase_order_number
      purchase_order_number_enabled
      invoice_percentage
      invoice_percentage_enabled
      payroll_date
    }
  }
`;

export const getAllPayrollReports = gql`
  query getAllPayrollReports(
    $organisationId: Int!
    $limit: Int
    $offset: Int
    $orderBy: [payroll_reports_order_by!]
  ) {
    payroll_reports(
      order_by: $orderBy
      where: { organisation_id: { _eq: $organisationId } }
      limit: $limit
      offset: $offset
    ) {
      id
      month
      year
      status
      version
      generation_date
    }
    payroll_reports_aggregate(
      where: { organisation_id: { _eq: $organisationId } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getPayrollDetails = gql`
  query getPayrollDetails($payroll_id: Int!) {
    payroll_reports(where: { id: { _eq: $payroll_id } }) {
      year
      month
      status
      total_scavc
      total_amount
      generation_date
      total_employee_contribution
      organisation {
        id
        name
      }
    }
  }
`;

export const getPayrollReportPlansData = gql`
  query getPayrollReportPlansData(
    $payroll_id: Int!
    $limit: Int
    $offset: Int
    $orderBy: [payroll_report_plan_logs_order_by!]
  ) {
    payroll_report_plan_logs(
      limit: $limit
      offset: $offset
      order_by: $orderBy
      where: {
        payroll_report_plans: { payroll_report_id: { _eq: $payroll_id } }
      }
    ) {
      id
      plan_id
      plan_status
      converted_avc
      first_name
      last_name
      payroll_number
      ni_number
      salary_sacrifice_amount
      employer_contribution
      total_contribution_amount
      provider
      pension_type
    }
    payroll_report_plan_logs_aggregate(
      where: {
        payroll_report_plans: { payroll_report_id: { _eq: $payroll_id } }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const getInvoices = gql`
  query getInvoices(
    $limit: Int!
    $offset: Int!
    $organisationId: Int!
    $orderBy: [invoices_order_by!]
  ) {
    invoices(
      limit: $limit
      offset: $offset
      order_by: $orderBy
      where: { payroll_report: { organisation_id: { _eq: $organisationId } } }
    ) {
      id
      invoice_no
      invoice_date
      invoice_percentage
      payroll_report {
        id
        year
        month
        organisation_id
      }
    }
    invoices_aggregate(
      where: { payroll_report: { organisation_id: { _eq: $organisationId } } }
    ) {
      aggregate {
        count
      }
    }
  }
`;
