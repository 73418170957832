import facebook from "../assets/images/facebook.png";
import linkedin from "../assets/images/linkedin.png";
import twitter from "../assets/images/twitter.png";
import instagram from "../assets/images/insta.png";
import store from "../store";

const passwordRegexp = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_]).{12,}$/;

const NINumberRegex =
  /^(?:[A-CEGHJ-PR-TW-Za-ceghj-pr-tw-z][A-CEGHJ-NPR-TW-Za-ceghj-npr-tw-z] ?\d{6} ?[A-Da-d])$/;

const UKTelephoneRegex = /^(?:\d{3}|\d{4})\s?\d{6}\b.{0,5}$/;

const referralCodeRegex = /^\s*([A-Z]{3}-\d{6})\s*$/;

const UKMobileRegex =
  /^((?:\d{3}|\d{4})\s?\d{8}$|(((\s?\(0\)\s?|\s0\s|\s)?)|0)7\d(\s)?\d{3}(\s)?\d{4,5})$/;

const integerRegex = /^\d*$/;

const eventPathRegex = /^\/events\/[^/]+$/;

const floatRegex = /^[0-9]+(\.[0-9]{1,2})?$/;

const dashesRegex = /^__ ______ _$/;

const organisations = [
  {
    name: "Organisation 1",
    value: "Organisation 1",
  },
  {
    name: "Organisation 2",
    value: "Organisation 2",
  },
  {
    name: "Organisation 3",
    value: "Organisation 3",
  },
  {
    name: "Organisation 4",
    value: "Organisation 4",
  },
];

const userRoles = {
  ORGANISATION_ADMIN: "organisation_admin",
  ORGANISATION_EMPLOYEE: "organisation_employee",
};

const payDatesOptions = [...Array(31)].map((_, i) => ({
  value: i + 1,
  name: (i + 1).toString(),
}));

const mmmThemes = {
  MY_RETIREMENTS_MATTERS: "My Retirement Matters",
};

const platforms = [
  { name: "Poster", value: "Poster" },
  { name: "Email", value: "Email" },
  { name: "Internet", value: "Internet" },
  { name: "E-Newsletter", value: "E-Newsletter" },
];

const applicationFormInitialValues = {
  ni_number: "",
  title: "",
  gender: "",
  first_name: "",
  last_name: "",
  date_of_birth: "",
  mobile_number: "",
  email: "",
  address: "",
  address1: "",
  address2: "",
  town: "",
  county: "",
  postcode: "",
  employee_number: "",
  referral_code: "",
  income: "",
  avcAmount: "",
  incomeFrequency: "",
  annualSalary: "",
  hours_per_week: "",
  age: "",
  salarySacrifice: "",
  PTCheckbox: false,
  planFrequency: 5,
  contributionAmount: 50,
  previousEmployerPlan: false,
  combiningWithThisEmployer: false,
  keepSeparateWithEmployer: false,
  triggeredTheMpaa: false,
  MPAAdate: null,
  confirmCheckbox: false,
  pensionFund: "",
  provider: "",
  accessedPensionBtn: false,
  RnUCheckbox: false,
  listCheckbox: true,
  lifestyleCheckbox: false,
  investmentChoices: [],
  totalPercent: "",
  pensionAge: "",
  retirementAge: "",
  termCondition: false,
  prudentialConsent: false,
  pensionSchemeBenefits: false,
  signature: "",
};

const profileInitialValues = {
  title: "",
  gender: "",
  first_name: "",
  last_name: "",
  date_of_birth: "",
  email: "",
  age: 0,
  mobile_number: "",
  telephone_number: "",
  employee_number: "",
  address1: "",
  address2: "",
  town: "",
  county: "",
  postcode: "",
  referral_code: "",
  referral_email: "",
  annual_salary: "",
  department: "",
  salary_bracket: "",
  age_bracket: "",
};

const changePasswordInitialValues = {
  currentPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

const organisationNames = {
  ABERDEENSHIRE_COUNCIL: "aberdeenshire council",
};

const genders = [
  {
    name: "Male",
    value: "Male",
  },
  {
    name: "Female",
    value: "Female",
  },
];

const titles = [
  {
    name: "Mr",
    value: "Mr",
  },
  {
    name: "Ms",
    value: "Ms",
  },
  {
    name: "Mrs",
    value: "Mrs",
  },
  {
    name: "Mx",
    value: "Mx",
  },
  {
    name: "Miss",
    value: "Miss",
  },
  {
    name: "Dr",
    value: "Dr",
  },
  {
    name: "Prof",
    value: "Prof",
  },
  {
    name: "Rev",
    value: "Rev",
  },
  {
    name: "Capt",
    value: "Capt",
  },
];

const ageBracket = [
  {
    name: "18 - 24",
    value: "18 - 24",
  },
  {
    name: "25 - 34",
    value: "25 - 34",
  },
  {
    name: "35 - 44",
    value: "35 - 44",
  },
  {
    name: "45 - 54",
    value: "55 - 64",
  },
  {
    name: "65 +",
    value: "65 +",
  },
];

const salaryBracket = [
  {
    name: "£0 - £12500",
    value: "£0 - £12500",
  },
  {
    name: "£12501 - £50270",
    value: "£12501 - £50270",
  },
  {
    name: "£50271 - £150000",
    value: "£50271 - £150000",
  },
  {
    name: "Over £150000",
    value: "Over £150000",
  },
];

const wizardSteps = () => {
  let sharedTitle = store.getState().multiForm.sharedTitle;
  return [
    {
      id: "1",
      label: "Personal Details",
    },
    {
      id: "2",
      label: `${sharedTitle} amount`,
    },
    {
      id: "3",
      label: "Investment Choices",
    },
    {
      id: "4",
      label: "Acknowledgement and signature",
    },
  ];
};

const planStatuses = {
  Cancelled: "cancel",
  Accepted: "accept",
  "Awaiting Authorisation": "awaiting",
  "Opted-out": "cancel",
  "Opt Out Pending": "awaiting",
  "Awaiting Provider Match": "awaiting",
  "On Hold – Existing AVC": "awaiting",
  "On Hold - NMW issue": "awaiting",
  "On Hold – ID issue": "awaiting",
  "On Hold – Low Earnings Limit": "awaiting",
  "On Hold - Provider Match Issue": "awaiting",
  "On Hold - Awaiting Employer Action": "awaiting",
  "On Hold – Awaiting Employee Acceptance": "awaiting",
};

const sliderMarks = [
  {
    name: 1,
    value: "1",
  },
  {
    name: 60,
    value: "60",
  },
];

const providers = {
  LEGAL_AND_GENERAL: "legal & general",
};

const pensionAgeOptions = [
  {
    name: "60",
    value: "sixty",
  },
  {
    name: "65",
    value: "sixty_five",
  },
  {
    name: "State pension age",
    value: "state_pension_age",
  },
];

const investmentChoicesCheckboxes = [
  {
    name: "listCheckbox",
    label: "Select from a list of Investment Funds",
  },
  {
    name: "lifestyleCheckbox",
    label: "Select from a list of Lifestyling Option",
  },
];

const footerLinks = [
  {
    name: "Accessibility",
    link: `${process.env.REACT_APP_MMM_BASE_URL}/accessibility`,
  },
  {
    name: "Cookies",
    link: `${process.env.REACT_APP_MMM_BASE_URL}/cookies`,
  },
  {
    name: "Privacy Policy",
    link: `${process.env.REACT_APP_MMM_BASE_URL}/private-policy`,
  },
  {
    name: "Terms & Conditions",
    link: `${process.env.REACT_APP_MMM_BASE_URL}/terms-conditions`,
  },
];

const footerSocialMediaLinks = [
  {
    name: "instagram",
    iconPath: instagram,
    link: "https://www.instagram.com/mymoneymatters.uk?igsh=aDcybTA2NDdja3Yx",
  },
  {
    name: "facebook",
    iconPath: facebook,
    link: "https://www.facebook.com/mymoneymattersuk",
  },
  {
    name: "twitter",
    iconPath: twitter,
    link: "https://twitter.com/mymoneymatters_",
  },
  {
    name: "linkedin",
    iconPath: linkedin,
    link: "https://www.linkedin.com/company/avc-wise",
  },
];

const ProfileButtonDetails = [
  {
    step: 1,
    title: "Update personal details",
  },
  {
    step: 2,
    title: "Security tab",
  },
];

const TRACKING_NINGI_OPTIONS = {
  tracking_module: "My Investment Advice",
  application_type: "Ningi",
  field_state: "editing",
};
const TRACKING_HOME_OPTIONS = {
  tracking_module: "Home",
  application_type: "AVC Wise",
  field_state: "editing",
};
const TRACKING_STAFF_BENEFITS_OPTIONS = {
  tracking_module: "Staff Benefits Page",
  application_type: "AVC Wise",
  field_state: "editing",
};
const TRACKING_MANAGE_MY_SHARED_COST_OPTIONS = {
  tracking_module: "Manage My Shared Cost AVC",
  application_type: "AVC Wise",
  field_state: "editing",
};
const TRACKING_CHANGE_PORTAL_OPTIONS = {
  tracking_module: "Change Portal",
  application_type: "AVC Wise",
  field_state: "editing",
};
const TRACKING_EVENTS_PAGE_OPTIONS = {
  tracking_module: "Events Page",
  application_type: "AVC Wise",
  field_state: "editing",
};
const TRACKING_CONTACT_PAGE_OPTIONS = {
  tracking_module: "Contact Page",
  application_type: "AVC Wise",
  field_state: "editing",
};

const TRACKING_PROFILE_OPTIONS = {
  tracking_module: "Profile",
  application_type: "AVC Wise",
  field_state: "editing",
};

const TRACKING_NAF_OPTIONS = {
  tracking_module: "Application",
  application_type: "New",
  field_state: "editing",
};
const TRACKING_NAF_STARTED_OPTIONS = {
  tracking_module: "Application",
  application_type: "New",
  field_state: "started",
};
const TRACKING_FOOTER_OPTIONS = {
  tracking_module: "Footer",
  application_type: "AVC Wise",
  field_state: "editing",
};
const TRACKING_HEADER_OPTIONS = {
  tracking_module: "Header",
  application_type: "AVC Wise",
  field_state: "editing",
};
const TRACKING_PAYROLL_PAGE_OPTIONS = {
  tracking_module: "Payroll Page",
  application_type: "AVC Wise",
  field_state: "editing",
};
const TRACKING_INVOICE_PAGE_OPTIONS = {
  tracking_module: "Invoice Page",
  application_type: "AVC Wise",
  field_state: "editing",
};
const TOKEN_EXPIRES_MESSAGE = "Your session has expired. Please login again.";

const UserEventsDetails = () => {
  let formSharedTitle = store.getState().multiForm.formSharedTitle;
  return [
    //checkbox
    {
      name: "PTCheckbox",
      field_name: "Do not fall below Primary Threshold",
    },
    {
      name: "confirmCheckbox",
      field_name: "Confidence in Funds to Invest in without Investment Advice",
    },
    {
      name: "RnUCheckbox",
      field_name: "Fund Investment Decision Declaration",
    },
    {
      name: "lifestyleCheckbox",
      field_name: "Lifestyling Option",
    },
    {
      name: "listCheckbox",
      field_name: "Investment Funds",
    },
    {
      name: "termCondition",
      field_name: "Term and conditions - Acknowledgement and signature",
    },
    {
      name: "prudentialConsent",
      field_name: "prudential Consent - Acknowledgement and signature",
    },
    {
      name: "pensionSchemeBenefits",
      field_name: "NHS Pension Scheme Benefits - Acknowledgement and signature",
    },
    //maskedInput
    {
      name: "ni_number",
      field_name: "National Insurance Number",
    },
    //textField
    {
      name: "employee_number",
      field_name: "Employee Number",
    },
    {
      name: "first_name",
      field_name: "First Name",
    },
    {
      name: "last_name",
      field_name: "Last Name",
    },
    {
      name: "mobile_number",
      field_name: "Phone Number",
    },
    {
      name: "email",
      field_name: "Email",
    },
    {
      name: "referral_code",
      field_name: "Referral Code",
    },
    {
      name: "address1",
      field_name: "Home Address#1",
    },
    {
      name: "address2",
      field_name: "Home Address#2",
    },
    {
      name: "town",
      field_name: "Town",
    },
    {
      name: "country",
      field_name: "Country",
    },
    {
      name: "postcode",
      field_name: "Post Code",
    },
    {
      name: "hours_per_week",
      field_name: "Working hours per week",
    },
    {
      name: "pensionAge",
      field_name: "Pension Age",
    },
    {
      name: "retirementAge",
      field_name: "Retirement Age",
    },
    {
      name: "age",
      field_name: "Age",
    },
    {
      name: "otherReason",
      field_name: "Why have you decided to apply? - Other Reason",
    },
    // Date Field
    {
      name: "date_of_birth",
      field_name: "Date of Birth",
    },
    {
      name: "flexiblyAccessedDate",
      field_name: "First payment date",
    },
    {
      name: "MPAAdate",
      field_name: "MPAA Date",
    },
    // Text field with icon
    {
      name: "income",
      field_name: "Annual Salary",
    },
    {
      name: "avcAmount",
      field_name: "Afford to pay per pay period",
    },
    {
      name: "salarySacrifice",
      field_name: "My other salary sacrifice (total amount)",
    },
    // SwitchButton
    {
      name: "accessedPensionBtn",
      field_name: "Accessed Pension",
    },
    {
      name: "previousEmployerPlan",
      field_name: "Previous employer plan",
    },
    {
      name: "combiningWithThisEmployer",
      field_name: "Combining with this employer",
    },
    {
      name: "keepSeparateWithEmployer",
      field_name: "Keep separate with employer",
    },
    {
      name: "triggeredTheMpaa",
      field_name: "Triggered the Mpaa",
    },
    //autocomplete field
    {
      name: "address",
      field_name: "Type your address or postcode",
    },

    //slider
    {
      name: "planFrequency",
      field_name: "How long do you plan to make contributions",
    },

    //link
    {
      name: "link_mandg",
      field_name: "Link to mandg",
    },
    {
      name: "moveToSharedCostPage",
      field_name: `Move to ${formSharedTitle} page`,
    },
    // Select
    {
      name: "title",
      field_name: "Title",
    },
    {
      name: "incomeFrequency",
      field_name: "How often do you get paid ?",
    },
    {
      name: "pensionFund",
      field_name: "Pension Fund",
    },
    {
      name: "provider",
      field_name: "Provider",
    },
    {
      name: "planReason",
      field_name: "Why have you decided to apply?",
    },
  ];
};

const auth0PasswordErrorMessages = {
  403: "Your current password is wrong.",
  429: "Your account has been blocked after multiple consecutive attempts.",
  400: "This password has previously been used please try another one.",
};

const organisationWarningMessages = {
  PAY_PERIOD:
    "Sorry, but your organisation is missing some pay period information and your request cannot be completed at this time. Please get in touch with one of our team using the chat at the bottom of the screen to help resolve the issue.",
  PROVIDER: "Sorry! No provider is present for this organisation.",
  PENSION_FUND: "Sorry! No pension fund is present for this organisation.",
};

const profileRequiredKeys = [
  {
    key: "title",
    tab: "1",
  },
  {
    key: "gender",
    tab: "1",
  },
  {
    key: "first_name",
    tab: "1",
  },
  {
    key: "last_name",
    tab: "1",
  },
  {
    key: "telephone_number",
    tab: "1",
  },
  {
    key: "mobile_number",
    tab: "1",
  },
  {
    key: "date_of_birth",
    tab: "1",
  },
  {
    key: "postcode",
    tab: "2",
  },
  {
    key: "town",
    tab: "2",
  },
  {
    key: "address1",
    tab: "2",
  },
];

const getProfileTouchedValues = (personalDetails) => {
  return {
    title: !personalDetails?.title,
    gender: !personalDetails?.gender,
    first_name: !personalDetails?.first_name,
    last_name: !personalDetails?.last_name,
    telephone_number: !personalDetails?.telephone_number,
    mobile_number: !personalDetails?.mobile_number,
    date_of_birth: !personalDetails?.date_of_birth,
    postcode: !personalDetails?.postcode,
    town: !personalDetails?.town,
    address1: !personalDetails?.address1,
  };
};

export {
  passwordRegexp,
  NINumberRegex,
  referralCodeRegex,
  UKTelephoneRegex,
  UKMobileRegex,
  integerRegex,
  floatRegex,
  eventPathRegex,
  organisations,
  platforms,
  applicationFormInitialValues,
  pensionAgeOptions,
  titles,
  planStatuses,
  dashesRegex,
  wizardSteps,
  sliderMarks,
  TRACKING_NINGI_OPTIONS,
  TRACKING_PROFILE_OPTIONS,
  TRACKING_NAF_OPTIONS,
  TRACKING_HOME_OPTIONS,
  TRACKING_MANAGE_MY_SHARED_COST_OPTIONS,
  TRACKING_INVOICE_PAGE_OPTIONS,
  TRACKING_CHANGE_PORTAL_OPTIONS,
  TRACKING_EVENTS_PAGE_OPTIONS,
  TRACKING_CONTACT_PAGE_OPTIONS,
  TRACKING_FOOTER_OPTIONS,
  TRACKING_HEADER_OPTIONS,
  TOKEN_EXPIRES_MESSAGE,
  TRACKING_STAFF_BENEFITS_OPTIONS,
  investmentChoicesCheckboxes,
  TRACKING_NAF_STARTED_OPTIONS,
  TRACKING_PAYROLL_PAGE_OPTIONS,
  footerLinks,
  userRoles,
  genders,
  footerSocialMediaLinks,
  UserEventsDetails,
  ProfileButtonDetails,
  ageBracket,
  salaryBracket,
  profileInitialValues,
  changePasswordInitialValues,
  auth0PasswordErrorMessages,
  organisationWarningMessages,
  profileRequiredKeys,
  getProfileTouchedValues,
  payDatesOptions,
  mmmThemes,
  providers,
  organisationNames,
};
