import SharedHeader from "../../generic-components/shared-header/SharedHeader";
import FormHeader from "../registered-user/shared-cost-application/header/FormHeader";
import LoadingButton from "../../generic-components/button";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import SwitchButton from "../../generic-components/toggle-switch-button";
import { useEffect, useMemo, useState } from "react";
import Modal from "@mui/material/Modal";
import FormSelect from "../../generic-components/form-select";
import { getOrgPayrollSetting } from "../../../graphql/queries/payrollReport";
import { useLazyQuery, useMutation } from "@apollo/client";
import useStepper from "../../../hooks/useStepper";
import {
  createOrgPayrollSetting,
  updateOrgPayrollSetting,
} from "../../../graphql/mutations/payrollSettings";
import moment from "moment";
import {
  runPayrollReport,
  uploadManualReport,
} from "../../../graphql/mutations/payrollReport";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import IconButton from "../../generic-components/icon-btn";
import TextInput from "../../generic-components/input-text";
import {
  payrollSettingsVaidationSchema,
  runReportValidationSchema,
  uploadReportValidationSchema,
} from "../../../schema";
import {
  payDatesOptions,
  TRACKING_PAYROLL_PAGE_OPTIONS,
} from "../../../constants";
import {
  Paper,
  Grid,
  Box,
  Container,
  Stack,
  Typography,
  Divider,
} from "@mui/material";
import {
  getCurrentAndNextMonthArray,
  getPayPeriodName,
} from "../../../helpers";
import { getPayPeriod } from "../../../graphql/queries/payPeriods";
import Loader from "../../generic-components/loader";
import FileInput from "../../generic-components/file-input";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "5px",
  p: 4,
};

const PayrollOrganisationDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const isEditAction = params?.action === "edit";
  const { setErrorToast, setSaveError, setSuccessToast } = useStepper();

  const [isLoading, setIsLoading] = useState(true);
  const [openUpload, setOpenUpload] = useState(false);
  const [openRunReport, setOpenRunReport] = useState(false);
  const [isPayrollSettings, setIsPayrollSettings] = useState(false);
  const [base64CSV, setBase64CSV] = useState("");
  const [payPeriods, setPayPeriods] = useState([]);
  const [payPeriodIndentifiers, setPayPeriodIndentifiers] = useState([]);
  const [settingValues, setSettingValues] = useState({
    payroll_date: null,
    invoice_percentage: null,
    invoice_percentage_enabled: true,
    purchase_order_number: "",
    purchase_order_number_enabled: false,
    payroll_frequency: "",
    payroll_identifier: "",
  });

  const [fetchPayrollSettings] = useLazyQuery(getOrgPayrollSetting);
  const [updatePayrollSettings, { loading: updateSettingsLoading }] =
    useMutation(updateOrgPayrollSetting);
  const [createPayrollSettings, { loading: createSettingsLoding }] =
    useMutation(createOrgPayrollSetting);
  const [uploadCSVFile, { loading: uploadCSVLoading }] =
    useMutation(uploadManualReport);
  const [runPayroll, { loading: runReportLoading }] =
    useMutation(runPayrollReport);

  const handleClose = () => {
    setOpenUpload(false);
    setBase64CSV("");
    setOpenRunReport(false);
  };

  const [getPayPeriodDetails] = useLazyQuery(getPayPeriod, {
    fetchPolicy: "no-cache",
    refetchWritePolicy: "overwrite",
    variables: {
      organisation_id: Number(params?.organisationId) || 0,
    },
    onCompleted: (data) => {
      setPayPeriods(
        data?.pay_periods?.map((item) => ({
          name: getPayPeriodName(item.period),
          value: getPayPeriodName(item.period),
        }))
      );
      setPayPeriodIndentifiers(
        data?.pay_periods?.map((item) => ({
          name: item.pay_period_identifier,
          value: item.pay_period_identifier,
        }))
      );
    },
    onError: (error) => {
      setSaveError(error);
      setErrorToast(true);
    },
  });

  const getOrganisationSettings = async () => {
    setIsLoading(true);
    fetchPayrollSettings({
      fetchPolicy: "no-cache",
      refetchWritePolicy: "overwrite",
      variables: {
        organisation_id: Number(params?.organisationId) || 0,
      },
      onCompleted: (data) => {
        setIsPayrollSettings(!!data?.organisation_payroll_settings[0] || false);
        setSettingValues(
          (prev) => data?.organisation_payroll_settings[0] || prev
        );
        setIsLoading(false);
      },
      onError: (error) => {
        setSaveError(error);
        setErrorToast(true);
        setIsLoading(false);
      },
    });
  };

  const savePayrollSettings = async (values) => {
    let variables = {
      organisation_id: Number(params?.organisationId) || 0,
      payroll_date: Number(values?.payroll_date),
      payroll_frequency: values?.payroll_frequency || "",
      payroll_identifier: values?.payroll_identifier || "",
      purchase_order_number: values?.purchase_order_number || "",
      invoice_percentage_enabled: !!values?.invoice_percentage_enabled,
      purchase_order_number_enabled: !!values?.purchase_order_number_enabled,
      invoice_percentage: parseFloat(values?.invoice_percentage).toFixed(2),
    };
    const successMessage = "Payroll settings has been saved successfully.";

    if (isPayrollSettings) {
      variables.updated_at = moment().format();
      updatePayrollSettings({
        variables,
        onCompleted: () => {
          setSuccessToast(successMessage);
        },
        onError: (error) => {
          setSaveError(error);
          setErrorToast(true);
        },
      });
    } else {
      variables.created_at = moment().format();
      createPayrollSettings({
        variables,
        onCompleted: () => {
          setIsPayrollSettings(true);
          setSuccessToast(successMessage);
        },
        onError: (error) => {
          setSaveError(error);
          setErrorToast(true);
        },
      });
    }
  };

  const handleFileChange = (e, setFieldValue) => {
    const file = e?.target?.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");
        setBase64CSV(base64String);
        setFieldValue("csv_file", file);
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadFile = async () => {
    uploadCSVFile({
      variables: {
        csvFile: base64CSV,
      },
      onCompleted: (data) => {
        handleClose();
        setSuccessToast(data?.UploadManualPayrollReport?.message);
      },
      onError: (error) => {
        setErrorToast(true);
        setSaveError({ message: error?.message, overRideCustom: true });
      },
    });
  };

  const handleRunPayrollReport = async (values) => {
    runPayroll({
      variables: {
        organisationId: Number(params?.organisationId) || 0,
        month: values.month,
      },
      onCompleted: (data) => {
        handleClose();
        setSuccessToast(data?.RunPayrollReport?.message);
      },
      onError: (error) => {
        setErrorToast(true);
        setSaveError({ message: error?.message, overRideCustom: true });
      },
    });
  };

  const queryLoaders = useMemo(
    () =>
      isLoading ||
      updateSettingsLoading ||
      createSettingsLoding ||
      uploadCSVLoading ||
      runReportLoading,
    [
      isLoading,
      updateSettingsLoading,
      createSettingsLoding,
      uploadCSVLoading,
      runReportLoading,
    ]
  );

  useEffect(() => {
    getPayPeriodDetails();
    getOrganisationSettings();
  }, [params?.organisationId]);

  return (
    <Box className="application-page-container mt-150 mb-50">
      <SharedHeader
        heading="Organisation Payroll Settings"
        displayLogo={false}
      />
      <Container>
        <IconButton
          trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
          buttonTitle="Back to Organisations"
          styleClass="light-backbtn mb-10"
          handleClick={() => navigate("/payroll_organisations")}
          icon={<KeyboardArrowLeftIcon />}
        />
        <FormHeader heading={params?.organisationName || ""} />
        {isLoading ? (
          <Box className="mt-100">
            <Loader />
          </Box>
        ) : (
          <Paper sx={{ minWidth: 350 }}>
            <Formik
              enableReinitialize
              initialValues={settingValues}
              validationSchema={payrollSettingsVaidationSchema}
              onSubmit={savePayrollSettings}
            >
              {({ values }) => (
                <Form>
                  <Grid container className="p-lr-30">
                    <Grid item xs={12} sm={10} md={6}>
                      <FormSelect
                        name="payroll_frequency"
                        labelId="payrollFrequency"
                        menuItems={payPeriods || []}
                        disabled={!isEditAction}
                        label="Payroll Frequency"
                        trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
                      />
                      <FormSelect
                        name="payroll_identifier"
                        labelId="payrollIdentifier"
                        menuItems={payPeriodIndentifiers || []}
                        disabled={!isEditAction}
                        label="Payroll Identifier"
                        trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
                      />
                      <FormSelect
                        name="payroll_date"
                        labelId="payrollDate"
                        menuItems={payDatesOptions}
                        disabled={!isEditAction}
                        label="Payroll Date"
                        trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
                      />
                      <TextInput
                        id="purchase_order_number"
                        name="purchase_order_number"
                        label="Purchase Order Number"
                        disabled={!isEditAction}
                        trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
                        value={values?.purchase_order_number || ""}
                      />
                      <SwitchButton
                        name="purchase_order_number_enabled"
                        value={values?.purchase_order_number_enabled || false}
                        disabled={!isEditAction}
                        trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
                      />
                      <TextInput
                        id="invoice_percentage"
                        name="invoice_percentage"
                        label="Invoice Percentage"
                        disabled={!isEditAction}
                        trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
                        value={values?.invoice_percentage || null}
                      />
                      <SwitchButton
                        name="invoice_percentage_enabled"
                        disabled={!isEditAction}
                        value={values?.invoice_percentage_enabled || false}
                        trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
                      />
                      <Stack
                        direction="column"
                        alignItems="flex-start"
                        className="mt-50 mb-50"
                      >
                        {isEditAction ? (
                          <LoadingButton
                            buttonTitle="Save"
                            type="submit"
                            styleClass="btn primary-clr-btn"
                            trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
                            disabled={queryLoaders}
                            loading={
                              updateSettingsLoading || createSettingsLoding
                            }
                          />
                        ) : (
                          <LoadingButton
                            buttonTitle="Edit"
                            styleClass="btn primary-clr-btn"
                            handleClick={(e) => {
                              e?.preventDefault();
                              navigate(
                                `/payroll_organisation/edit/${encodeURIComponent(
                                  params?.organisationName
                                )}/${params?.organisationId}`
                              );
                            }}
                            trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
                            loading={queryLoaders}
                            disabled={queryLoaders}
                          />
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
            <Divider />
            <Grid item xs={12}>
              <LoadingButton
                buttonTitle="Payroll Report"
                styleClass="btn primary-clr-btn mt-10 ml-10"
                trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
                handleClick={() =>
                  navigate(
                    `/payroll_report/${encodeURIComponent(
                      params?.organisationName
                    )}/${params?.organisationId}`
                  )
                }
                disabled={queryLoaders || !isEditAction}
              />
              <LoadingButton
                buttonTitle="Upload Manual Report"
                styleClass="btn primary-clr-btn mt-10 ml-10"
                trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
                handleClick={() => setOpenUpload(true)}
                disabled={queryLoaders || !isEditAction}
              />
              <LoadingButton
                buttonTitle="Run Payroll Report"
                styleClass="btn primary-clr-btn mt-10 ml-10"
                trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
                handleClick={() => setOpenRunReport(true)}
                disabled={queryLoaders || !isEditAction}
              />
              <LoadingButton
                buttonTitle="Invoices"
                trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
                styleClass="btn primary-clr-btn mt-10 ml-10"
                handleClick={() =>
                  navigate(
                    `/invoice/${encodeURIComponent(params?.organisationName)}/${
                      params?.organisationId
                    }`
                  )
                }
                disabled={queryLoaders || !isEditAction}
              />
            </Grid>
          </Paper>
        )}
      </Container>
      <Modal
        open={openUpload}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Formik
            validationSchema={uploadReportValidationSchema}
            initialValues={{ csv_file: null }}
            onSubmit={uploadFile}
          >
            {({ setFieldValue }) => (
              <Form>
                <Typography className="mb-10" align="center">
                  <strong>Upload Manual Report</strong>
                </Typography>
                <FileInput
                  id="csv_file"
                  name="csv_file"
                  accept="application/csv"
                  disabled={queryLoaders}
                  trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
                  handleCustomChange={(e) => {
                    handleFileChange(e, setFieldValue);
                  }}
                />
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={1}
                  className="mt-30"
                >
                  <LoadingButton
                    buttonTitle="Cancel"
                    trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
                    styleClass={
                      "btn primary-clr-btn smallbtn confirmation-warning"
                    }
                    handleClick={handleClose}
                    disabled={queryLoaders}
                  />
                  <LoadingButton
                    buttonTitle="Upload"
                    trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
                    styleClass={"btn primary-clr-btn smallbtn"}
                    type="submit"
                    disabled={queryLoaders}
                    loading={uploadCSVLoading}
                  />
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
      <Modal
        open={openRunReport}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Formik
            initialValues={{ month: "" }}
            validationSchema={runReportValidationSchema}
            onSubmit={handleRunPayrollReport}
          >
            {({ values }) => (
              <Form>
                <Typography className="mb-10" align="center">
                  <strong>Run Payroll Report</strong>
                </Typography>
                <FormSelect
                  name="month"
                  labelId="month"
                  label="Payroll Month *"
                  value={values?.month || ""}
                  disabled={queryLoaders}
                  menuItems={getCurrentAndNextMonthArray() || []}
                  trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
                />
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={1}
                  className="mt-30"
                >
                  <LoadingButton
                    buttonTitle="Cancel"
                    trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
                    styleClass={
                      "btn primary-clr-btn smallbtn confirmation-warning"
                    }
                    handleClick={handleClose}
                    disabled={queryLoaders}
                  />
                  <LoadingButton
                    buttonTitle="Run"
                    trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
                    styleClass={"btn primary-clr-btn smallbtn"}
                    type="submit"
                    disabled={queryLoaders}
                    loading={runReportLoading}
                  />
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </Box>
  );
};

export default PayrollOrganisationDetail;
