import { Stack, Box, Typography, Container } from "@mui/material";
import LoadingButton from "../../../../generic-components/button";
import { mmmUniversalRedirection } from "../../../../../helpers";
import useStepper from "../../../../../hooks/useStepper";
import { TRACKING_HOME_OPTIONS, mmmThemes } from "../../../../../constants";

const HomePageTextSection = () => {
  const { personalDetails } = useStepper();
  return (
    <Container>
      <Stack
        direction={{ xs: "column", sm: "column", md: "row", lg: "row" }}
        spacing={2}
        sx={{
          display: "flex",
        }}
        className="mt-50"
      >
        <Box
          sx={{
            flex: 0.6,
          }}
        >
          <Stack direction="column" spacing={2}>
            <Box sx={{ paddingBottom: 2, px: 3 }}>
              <Typography variant="h6" className="mt-30 header-bold-lg">
                Want to know more?
              </Typography>
              <Typography className="mt-30 home-typography dark-purple-text">
                This is an extra pot of money for retirement that you can choose
                to pay into alongside your main local government pension scheme.
              </Typography>
              <Typography className="mt-30" variant="subtitle1">
                <strong className="text-bold">Why is it good?</strong>
              </Typography>
              <Typography className="home-typography dark-purple-text ">
                The government gives you back the income tax and NI on
                everything you add.
              </Typography>
              <Typography className="mt-30" variant="subtitle1">
                <strong className="text-bold">How does it work?</strong>
              </Typography>
              <Typography className="home-typography dark-purple-text">
                The money is put directly into your retirement pot every time
                you get paid. In pensions jargon, this way of building your pot
                is known as{" "}
                <span className="italic-font"> Salary Sacrifice</span>.
              </Typography>
              <Typography className="mt-30" variant="subtitle1">
                <strong className="text-bold">What does that mean?</strong>
              </Typography>
              <Typography className="home-typography dark-purple-text">
                *If you pay in £100, you’ll get £138.67 added to your pot - an{" "}
                <strong className="primary-text">
                  instant saving of £38.67
                </strong>
              </Typography>
              <Typography className="mt-30" variant="subtitle1">
                <strong className="text-bold">
                  How much can I put in - and how often?
                </strong>
              </Typography>
              <Typography className="home-typography dark-purple-text">
                You choose an amount to put in every time you get paid, from as
                little as £2 a month.
              </Typography>
              <Typography className="mt-30" variant="subtitle1">
                <strong className="text-bold">
                  Can I change the amount I pay later?
                </strong>
              </Typography>
              <Typography className="home-typography dark-purple-text">
                Yes, you can update the amount as many times as you like.
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{ display: "block", mt: 2 }}
                className="home-typography-subtitle dark-purple-text"
              >
                *The estimated savings each payroll is not guaranteed and could
                vary depending on your circumstances and the rate at which you
                pay Income Tax.
              </Typography>
              <Typography
                sx={{ display: "block" }}
                className="home-typography-subtitle dark-purple-text"
              >
                These calculations are based on Income Tax at 20% (basic rate)
                and 40% (higher rate), and National Insurance contributions at
                8% (basic rate) and 2% (higher rate).
              </Typography>
            </Box>
          </Stack>
        </Box>

        <Box
          sx={{
            flex: 0.4,
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              boxShadow: "4px 4px 8px 0px rgba(0, 0, 0, 0.5)",
              p: 2,
            }}
          >
            <Typography variant="h6" className="mt-10  header-bold-lg">
              Join a Webinar
            </Typography>
            <Typography className="home-typography mt-30">
              Come to our live session where you can find out everything you
              want to know about{" "}
              <span className="primary-text">Shared Cost AVCs</span>, how they
              work and how you can use it to best effect.
            </Typography>
            <Typography className="home-typography mt-30">
              Come along, listen and ask any questions you have.
            </Typography>
            <LoadingButton
              buttonTitle="Book a Place"
              trackingDetails={TRACKING_HOME_OPTIONS}
              styleClass={"book-place-btn mt-18"}
              handleClick={() => {
                mmmUniversalRedirection(
                  personalDetails?.sub_domain,
                  `/employee/webinars/list/${mmmThemes.MY_RETIREMENTS_MATTERS}/${process.env.REACT_APP_MMM_RETIREMENT_THEME_ID}`
                );
              }}
            />
          </Box>
        </Box>
      </Stack>
    </Container>
  );
};

export default HomePageTextSection;
