import { gql } from "@apollo/client";

export const generateInvoice = gql`
  mutation generateInvoice($invoiceId: Int!) {
    GenerateInvoice(invoiceId: $invoiceId) {
      message
      success
      data
    }
  }
`;
