import SharedHeader from "../../generic-components/shared-header/SharedHeader";
import LoadingButton from "../../generic-components/button";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useMemo, useState } from "react";
import useStepper from "../../../hooks/useStepper";
import Loader from "../../generic-components/loader";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import IconButton from "../../generic-components/icon-btn";
import {
  getPayrollDetails,
  getPayrollReportPlansData,
} from "../../../graphql/queries/payrollReport";
import { TRACKING_PAYROLL_PAGE_OPTIONS } from "../../../constants";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  tableCellClasses,
  TablePagination,
  Box,
  Container,
  Grid,
  TableSortLabel,
  Typography,
} from "@mui/material";
import {
  downloadPayrollReportCSV,
  refreshPlanLog,
  reRunReport,
  saveAsFinal,
  saveAsPending,
} from "../../../graphql/mutations/payrollReport";
import ConfirmationDialog from "../../generic-components/confirmation-dialog";

const columns = [
  {
    id: "plan_status",
    label: "Status",
    align: "center",
  },
  {
    id: "converted_avc",
    label: "Converted avc",
    align: "center",
  },
  {
    id: "first_name",
    label: "First name",
    align: "center",
  },
  {
    id: "last_name",
    label: "Last name",
    align: "center",
  },
  {
    id: "payroll_number",
    label: "Payroll number",
    align: "center",
  },
  {
    id: "ni_number",
    label: "Ni number",
    align: "center",
  },
  {
    id: "salary_sacrifice_amount",
    label: "Salary sacrifice amount",
    align: "center",
  },
  {
    id: "employer_contribution",
    label: "Employee contribution",
    align: "center",
  },
  {
    id: "total_contribution_amount",
    label: "Total",
    align: "center",
  },
  {
    id: "provider",
    label: "Provider",
    align: "center",
  },
  {
    id: "pension_type",
    label: "Pension type",
    align: "center",
  },
];

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#391474",
    border: "1px solid #391474",
    color: "#fff",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    padding: "0.625rem",
  },
}));

const StyledTableRow = styled(TableRow)({
  backgroundColor: "transparent",
  border: "1px solid #E3E3E3",
});

const PayrollReportDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { setErrorToast, setSaveError, setSuccessToast } = useStepper();

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [orderBy, setOrderBy] = useState("created_at");
  const [order, setOrder] = useState("desc");
  const [payrollReportDetails, setPayrollReportDetails] = useState(null);
  const [payrollReportPlans, setPayrollReportPlans] = useState([]);
  const [refreshingPlan, setRefreshingPlan] = useState(null);
  const [refreshedPlans, setRefreshedPlans] = useState([]);

  const [savePayrollAsPending, { loading: savePendingLoading }] =
    useMutation(saveAsPending);
  const [refreshPlanLogData, { loading: refreshPlanLogLoading }] =
    useMutation(refreshPlanLog);
  const [reRunPayrollReport, { loading: reRunLoading }] =
    useMutation(reRunReport);
  const [savePayrollAsFinal, { loading: saveFinalLoading }] =
    useMutation(saveAsFinal);
  const [downloadCSV, { loading: downloadCSVLoading }] = useMutation(
    downloadPayrollReportCSV
  );
  const [fetchPayrollDetails] = useLazyQuery(getPayrollDetails);
  const [fetchPayrollReportPlansData, { loading: payrollReportsLoading }] =
    useLazyQuery(getPayrollReportPlansData);

  const handlePageChange = (_, newPage) => {
    setPage(newPage + 1);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(+e?.target?.value);
    setPage(1);
  };

  const handleSortChange = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(1);
  };

  const handleBack = () => {
    navigate(
      `/payroll_report/${encodeURIComponent(params?.organisationName)}/${
        params?.organisationId
      }`
    );
  };

  const getPayrollDetailsData = async () => {
    setIsLoading(true);
    fetchPayrollDetails({
      fetchPolicy: "no-cache",
      refetchWritePolicy: "overwrite",
      variables: {
        payroll_id: params?.payrollId,
      },
      onCompleted: (data) => {
        setPayrollReportDetails(data?.payroll_reports?.[0] || null);
        setIsLoading(false);
      },
      onError: (error) => {
        setSaveError(error);
        setErrorToast(true);
        setIsLoading(false);
      },
    });
  };

  const getPayrollPlansData = async () => {
    fetchPayrollReportPlansData({
      fetchPolicy: "no-cache",
      refetchWritePolicy: "overwrite",
      variables: {
        payroll_id: Number(params?.payrollId) || 0,
        limit: rowsPerPage,
        offset: rowsPerPage * (page - 1),
        orderBy: { [orderBy]: order },
      },
      onCompleted: (data) => {
        setPayrollReportPlans(data?.payroll_report_plan_logs || []);
        setCount(
          data?.payroll_report_plan_logs_aggregate?.aggregate?.count || 0
        );
      },
      onError: (error) => {
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };

  const handleDownloadCSV = async () => {
    downloadCSV({
      variables: {
        payrollReportId: Number(params?.payrollId) || 0,
      },
      onCompleted: (data) => {
        setSuccessToast(data?.DownloadPayrollReportCSV?.message);
      },
      onError: (error) => {
        setErrorToast(true);
        setSaveError({ message: error?.message, overRideCustom: true });
      },
    });
  };

  const handleSaveAsFinal = async () => {
    savePayrollAsFinal({
      variables: {
        payrollReportId: Number(params?.payrollId) || 0,
        organisationId: Number(payrollReportDetails?.organisation?.id) || 0,
      },
      onCompleted: (data) => {
        setSuccessToast(data?.SavePayrollAsFinal?.message);
        handleBack();
      },
      onError: (error) => {
        setErrorToast(true);
        setSaveError({ message: error?.message, overRideCustom: true });
      },
    });
  };

  const handleReRun = async () => {
    reRunPayrollReport({
      variables: {
        month: payrollReportDetails?.month || "",
        payrollReportId: Number(params?.payrollId) || 0,
        organisationId: Number(payrollReportDetails?.organisation?.id) || 0,
      },
      onCompleted: (data) => {
        setSuccessToast(data?.ReRunPayrollReport?.message);
      },
      onError: (error) => {
        setErrorToast(true);
        setSaveError({ message: error?.message, overRideCustom: true });
      },
    });
  };

  const handleRefreshPlan = async (planLogId) => {
    if (refreshedPlans?.find((id) => id === planLogId)) {
      setSaveError({
        message: "You have already refreshed this plan.",
        overRideCustom: true,
      });
      setErrorToast(true);
      setRefreshingPlan(null);
      return;
    }
    refreshPlanLogData({
      variables: {
        planLogId: Number(planLogId),
        payrollReportId: Number(params?.payrollId) || 0,
        organisationId: Number(payrollReportDetails?.organisation?.id) || 0,
      },
      onCompleted: (data) => {
        const updatedData = data?.RefreshPayrollPlan?.data;
        setPayrollReportPlans((prevPlans) =>
          prevPlans.map((plan) =>
            plan?.id === planLogId ? { ...plan, ...updatedData } : plan
          )
        );
        setRefreshedPlans((prevIds) => [...prevIds, planLogId]);
        setSuccessToast(data?.RefreshPayrollPlan?.message);
        setRefreshingPlan(null);
      },
      onError: (error) => {
        setErrorToast(true);
        setRefreshingPlan(null);
        setSaveError({ message: error?.message, overRideCustom: true });
      },
    });
  };

  const handleSaveAsPending = () => {
    if (!refreshedPlans || !refreshedPlans?.length) {
      setErrorToast(true);
      setSaveError({
        message:
          "Unable to Save as Pending, Please refresh some plans before continue.",
        overRideCustom: true,
      });
      return;
    }
    savePayrollAsPending({
      variables: {
        planLogIds: [...new Set(refreshedPlans)] || [],
        payrollReportId: Number(params?.payrollId) || 0,
        organisationId: Number(payrollReportDetails?.organisation?.id) || 0,
      },
      onCompleted: (data) => {
        setRefreshedPlans([]);
        setSuccessToast(data?.SavePayrollAsPending?.message);
        handleBack();
      },
      onError: (error) => {
        setErrorToast(true);
        setSaveError({ message: error?.message, overRideCustom: true });
      },
    });
  };

  const queryLoaders = useMemo(
    () =>
      isLoading ||
      reRunLoading ||
      saveFinalLoading ||
      refreshPlanLogLoading ||
      payrollReportsLoading ||
      savePendingLoading ||
      downloadCSVLoading,
    [
      isLoading,
      payrollReportsLoading,
      downloadCSVLoading,
      refreshPlanLogLoading,
      savePendingLoading,
      saveFinalLoading,
      reRunLoading,
    ]
  );

  useEffect(() => {
    getPayrollDetailsData();
  }, []);

  useEffect(() => {
    getPayrollPlansData();
  }, [page, rowsPerPage, orderBy, order]);

  return (
    <Box className="application-page-container mt-150 mb-100">
      <SharedHeader heading="Payroll Report Details" displayLogo={false} />
      <Container>
        <IconButton
          trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
          buttonTitle="Back to Payrolls"
          styleClass="light-backbtn mb-10"
          disabled={queryLoaders}
          handleClick={handleBack}
          icon={<KeyboardArrowLeftIcon />}
        />
        <Box className="payroll-report-header mb-30">
          <Box>
            Payroll Report : {payrollReportDetails?.month || ""}{" "}
            {payrollReportDetails?.year || ""}
          </Box>
          <Box>
            Organisation Name : {payrollReportDetails?.organisation?.name || ""}
          </Box>
          <Box>Status : {payrollReportDetails?.status || ""}</Box>
        </Box>
        {isLoading ? (
          <Box className="mt-50">
            <Loader />
          </Box>
        ) : (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => {
                    return (
                      <StyledTableCell
                        key={column.id}
                        align={column.align}
                        sortDirection={orderBy === column.id ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === column.id}
                          direction={orderBy === column.id ? order : "asc"}
                          onClick={() => handleSortChange(column.id)}
                          sx={{
                            "&.MuiTableSortLabel-root .MuiTableSortLabel-icon":
                              {
                                color: "white !important",
                              },
                            "&.MuiTableSortLabel-root": {
                              color: "white !important",
                            },
                            "&.Mui-active": {
                              color: "white !important",
                            },
                          }}
                        >
                          {column?.label}
                        </TableSortLabel>
                      </StyledTableCell>
                    );
                  })}
                  {params?.action === "edit" && (
                    <StyledTableCell align="center" width="8%" />
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {payrollReportsLoading ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={12} align="center">
                      <Loader size={35} />
                    </StyledTableCell>
                  </StyledTableRow>
                ) : payrollReportPlans?.length ? (
                  payrollReportPlans.map((planLog) => (
                    <StyledTableRow key={planLog?.id}>
                      {columns.map((column) => {
                        return (
                          <StyledTableCell key={column?.id} align="center">
                            {planLog[column?.id]}
                          </StyledTableCell>
                        );
                      })}
                      {params?.action === "edit" && (
                        <StyledTableCell align="center">
                          <LoadingButton
                            buttonTitle="Refresh"
                            trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
                            handleClick={() => {
                              setRefreshingPlan(planLog?.id);
                              handleRefreshPlan(planLog?.id);
                            }}
                            styleClass={"btn primary-clr-btn smallbtn"}
                            disabled={queryLoaders}
                            loading={refreshingPlan === planLog?.id}
                          />
                        </StyledTableCell>
                      )}
                    </StyledTableRow>
                  ))
                ) : (
                  <StyledTableRow>
                    <StyledTableCell colSpan={12} align="center">
                      No records found
                    </StyledTableCell>
                  </StyledTableRow>
                )}
                {!payrollReportsLoading && !!payrollReportPlans?.length && (
                  <StyledTableRow>
                    <StyledTableCell colSpan={6} align="left">
                      <Typography>
                        <b>Total</b>
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>
                        <b>{payrollReportDetails?.total_scavc || 0}</b>
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>
                        <b>
                          {payrollReportDetails?.total_employee_contribution ||
                            0}
                        </b>
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>
                        <b>{payrollReportDetails?.total_amount || 0}</b>
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell colSpan={3} />
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <TablePagination
          component="div"
          page={page - 1}
          rowsPerPageOptions={[4, 8, 12]}
          count={count || 0}
          labelRowsPerPage="Records per page"
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
        <Grid item xs={12} className="mt-10 mb-10">
          <LoadingButton
            buttonTitle="Save as Final"
            styleClass="btn primary-clr-btn ml-10 mt-10"
            trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
            handleClick={() => {
              setOpenConfirm(true);
            }}
            disabled={queryLoaders}
          />
          {params?.action === "edit" && (
            <>
              <LoadingButton
                buttonTitle="Re-run"
                styleClass="btn primary-clr-btn ml-10 mt-10"
                trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
                loading={reRunLoading}
                handleClick={handleReRun}
                disabled={queryLoaders}
              />
              <LoadingButton
                buttonTitle="Save as Pending"
                styleClass="btn primary-clr-btn ml-10 mt-10"
                trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
                loading={savePendingLoading}
                handleClick={handleSaveAsPending}
                disabled={queryLoaders}
              />
            </>
          )}
          <LoadingButton
            buttonTitle="Download CSV"
            trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
            styleClass="btn primary-clr-btn ml-10 mt-10"
            handleClick={handleDownloadCSV}
            loading={downloadCSVLoading}
            disabled={queryLoaders}
          />
        </Grid>
        <ConfirmationDialog
          open={openConfirm}
          onClose={() => {
            setOpenConfirm(false);
          }}
          isLoading={saveFinalLoading}
          headerText={"Final Confirmation"}
          confirmationText={"Do you want to make the report final?"}
          confirmBtnText={"Make the report final"}
          cancelBtnText={"Cancel"}
          trackingDetails={TRACKING_PAYROLL_PAGE_OPTIONS}
          onConfirmation={handleSaveAsFinal}
        />
      </Container>
    </Box>
  );
};

export default PayrollReportDetail;
