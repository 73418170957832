import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useLazyQuery, useMutation } from "@apollo/client";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import SharedHeader from "../../generic-components/shared-header/SharedHeader";
import LoadingButton from "../../generic-components/button";
import IconButton from "../../generic-components/icon-btn";
import Loader from "../../generic-components/loader";
import { TRACKING_INVOICE_PAGE_OPTIONS } from "../../../constants";
import { getInvoices } from "../../../graphql/queries/payrollReport";
import { generateInvoice } from "../../../graphql/mutations/invoice";
import useStepper from "../../../hooks/useStepper";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  tableCellClasses,
  TablePagination,
  Box,
  Container,
  TableSortLabel,
} from "@mui/material";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#391474",
    border: "1px solid #391474",
    color: "#fff",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    padding: "0.625rem",
  },
}));

const StyledTableRow = styled(TableRow)({
  backgroundColor: "transparent",
  border: "1px solid #E3E3E3",
});

const columns = [
  {
    id: "invoice_date",
    label: "Invoice Date",
    align: "center",
    allowSorting: true,
  },
  { id: "month", label: "Invoice Month", align: "center", allowSorting: false },
  { id: "year", label: "Invoice Year", align: "center", allowSorting: false },
  {
    id: "invoice_no",
    label: "Invoice Number",
    align: "center",
    allowSorting: true,
  },
];

const InvoiceList = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { setErrorToast, setSaveError, setSuccessToast } = useStepper();

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [invoices, setInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [generatingInvoice, setGeneratingInvoice] = useState(null);
  const [orderBy, setOrderBy] = useState("invoice_date");
  const [order, setOrder] = useState("desc");

  const [fetchInvoices] = useLazyQuery(getInvoices);
  const [generateInvoicePDF] = useMutation(generateInvoice);

  const getOrganisationInvoices = async () => {
    setIsLoading(true);
    fetchInvoices({
      fetchPolicy: "no-cache",
      refetchWritePolicy: "overwrite",
      variables: {
        limit: rowsPerPage,
        offset: rowsPerPage * (page - 1),
        organisationId: Number(params?.organisationId) || 0,
        orderBy: { [orderBy]: order },
      },
      onCompleted: (data) => {
        setInvoices(data?.invoices);
        setCount(data?.invoices_aggregate?.aggregate?.count || 0);
        setIsLoading(false);
      },
      onError: (error) => {
        setSaveError(error);
        setErrorToast(true);
        setIsLoading(false);
      },
    });
  };

  const handlePageChange = (_, newPage) => {
    setPage(newPage + 1);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(+e?.target?.value);
    setPage(1);
  };

  const handleSortChange = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(1);
  };

  const invoiceGeneration = async (invoiceId) => {
    setGeneratingInvoice(invoiceId);

    generateInvoicePDF({
      fetchPolicy: "no-cache",
      refetchWritePolicy: "overwrite",
      variables: { invoiceId },
      onCompleted: (data) => {
        const base64String = data?.GenerateInvoice?.data;

        if (base64String) {
          const binaryString = atob(base64String);
          const binaryData = new Uint8Array(binaryString?.length);
          for (let i = 0; i < binaryString?.length; i++) {
            binaryData[i] = binaryString.charCodeAt(i);
          }

          const blob = new Blob([binaryData], { type: "application/pdf" });
          const pdfUrl = URL.createObjectURL(blob);
          window.open(pdfUrl, "_blank");
        }

        setSuccessToast(data?.GenerateInvoice?.message);
        setGeneratingInvoice(null);
      },
      onError: (error) => {
        setSaveError({ message: error?.message, overRideCustom: true });
        setErrorToast(true);
        setGeneratingInvoice(null);
      },
    });
  };

  useEffect(() => {
    getOrganisationInvoices();
  }, [page, rowsPerPage, orderBy, order]);

  const getColumnValue = (column, invoice) => {
    if (column.id === "month") {
      return invoice?.payroll_report?.month || "";
    } else if (column.id === "year") {
      return invoice?.payroll_report?.year || "";
    } else {
      return invoice[column.id];
    }
  };

  return (
    <Box className="application-page-container mt-150 mb-100">
      <SharedHeader heading="Payroll Invoices" displayLogo={false} />
      <Container>
        <IconButton
          trackingDetails={TRACKING_INVOICE_PAGE_OPTIONS}
          buttonTitle="Back to Details"
          styleClass="light-backbtn mb-10"
          disabled={isLoading}
          handleClick={() =>
            navigate(
              `/payroll_organisation/edit/${encodeURIComponent(
                params?.organisationName
              )}/${params?.organisationId}`
            )
          }
          icon={<KeyboardArrowLeftIcon />}
        />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 550 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    sortDirection={orderBy === column.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={() => {
                        column.allowSorting && handleSortChange(column.id);
                      }}
                      sx={{
                        "&.MuiTableSortLabel-root .MuiTableSortLabel-icon": {
                          color: "white !important",
                        },
                        "&.MuiTableSortLabel-root": {
                          color: "white !important",
                        },
                        "&.Mui-active": {
                          color: "white !important",
                        },
                      }}
                    >
                      {column.label}
                    </TableSortLabel>
                  </StyledTableCell>
                ))}
                <StyledTableCell align="center" width="35%" />
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <StyledTableRow>
                  <StyledTableCell colSpan={12} align="center">
                    <Loader size={35} />
                  </StyledTableCell>
                </StyledTableRow>
              ) : invoices.length ? (
                invoices.map((invoice) => (
                  <StyledTableRow key={invoice.id}>
                    {columns.map((column) => {
                      const columnValue = getColumnValue(column, invoice);
                      return (
                        <StyledTableCell key={column.id} align="center">
                          {columnValue}
                        </StyledTableCell>
                      );
                    })}
                    <StyledTableCell align="center">
                      <LoadingButton
                        buttonTitle="Details"
                        trackingDetails={TRACKING_INVOICE_PAGE_OPTIONS}
                        handleClick={() => invoiceGeneration(invoice.id)}
                        styleClass="btn primary-clr-btn smallbtn"
                        loading={generatingInvoice === invoice.id}
                        disabled={isLoading || generatingInvoice}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={12} align="center">
                    No records found
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          page={page - 1}
          rowsPerPageOptions={[4, 8, 12]}
          count={count}
          labelRowsPerPage="Records per page"
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Container>
    </Box>
  );
};

export default InvoiceList;
