export const newApplicationFormPath = {
  DEFAULT_APPLICATION_PATH: "/",
  NEW_APPLICATION_FORM: "/new-application-form",
  NHS_APPLICATION_FORM: "/nhs-application-form",
  REDIRECT_INVESTMENT_GUIDE: "/redirect-avc_investment_guide",
  MY_INVESTMENT_ADVICES: "/my_investment_advice",
  THANKYOU_PAGE: "/thankyou",
  REDIRECT_APPLICATION_FORM: "/redirect-application-form",
  MAX_CONTRIBUTION_CALCULATOR: "/max-contribution-calculator",
  EVENTS: "/events",
  PARTNERS_EVENTS: "/partners_events",
  CHANGE_PORTAL: "/change_portal",
  HOME: "/home",
  MY_BOOKINGS: "/my_bookings",
  AMEND_MY_SHARED_COST_AVC: "/amend_my_shared_cost_avc",
  MANAGE_MY_SHARED_COST_AVC: "/manage_my_shared_cost_avc",
  CANCEL_MY_PLAN: "/cancel_my_plan",
  PROFILE: "/profile",
  STAFF_BENEFITS: "/staff_benefits",
  SCHEME_NOT_FOUND: "/scheme_not_found",
};

export const DEFAULT_TITLE = "AVC | New Application";

export const documentPathTitles = [
  {
    path: "new-application-form",
    title: "AVC | New Application",
  },
  {
    path: "nhs-application-form",
    title: "AVC | New Application",
  },
  {
    path: "redirect-application-form",
    title: "AVC | New Application",
  },
  {
    path: "home",
    title: "AVC | Home",
  },
  {
    path: "change_portal",
    title: "AVC | Change Portal",
  },
  {
    path: "partners_events",
    title: "AVC | Partners Events Page",
  },
  {
    path: "events",
    title: "AVC | Events Page",
  },
  {
    path: "my_bookings",
    title: "AVC | Events Page",
  },
  {
    path: "manage_my_shared_cost_avc",
    title: "AVC | Manage My Shared Cost AVC",
  },
  {
    path: "cancel_my_plan",
    title: "AVC | Cancel My Plan",
  },
  {
    path: "amend_my_shared_cost_avc",
    title: "AVC | Amend My Shared Cost AVC",
  },
  {
    path: "my_investment_advice",
    title: "AVC | My Investment Advice",
  },
  {
    path: "max-contribution-calculator",
    title: "AVC | Max Contribution Calculator",
  },
  {
    path: "thankyou",
    title: "AVC | Thank You Page",
  },
  {
    path: "contact",
    title: "AVC | Contact",
  },
  {
    path: "profile",
    title: "AVC | Profile",
  },
  {
    path: "staff_benefits",
    title: "AVC | Staff Benefits",
  },
  {
    path: "scheme_not_found",
    title: "AVC | Scheme Not Found",
  },
  {
    path: "payroll",
    title: "AVC | Payroll Page",
  },
  {
    path: "invoice",
    title: "AVC | Invoice Page",
  },
];
